import React, { useEffect, useState } from "react";
import { useProvider } from "../../providers";
import { Button, Form } from "react-bootstrap";
import { subscribePost } from "../../services/v1/subscribe.service";
import useQuery from "../../hooks/useQuery";
import { catalogsGet } from "../../services/v1/catalog.service";
import { useNavigate } from "react-router-dom";

export default function CreateSubscribePage() {
  const navigate = useNavigate();
  const { postId, fbId } = useQuery();
  // state
  const [catalogs, setCatalogs] = useState([]);
  const [selectCatalogId, setSelectCatalogId] = useState("");
  const [name, setName] = useState("");
  // provider
  const { profile } = useProvider();
  // useEffect
  useEffect(() => {
    getDataCatalogs();
  }, []);
  // function
  const getDataCatalogs = async () => {
    const data = await catalogsGet("", profile.jwt);
    setCatalogs(data);
  };
  const postSubscribe = async () => {
    await subscribePost(
      { postId, fbId, catalogId: selectCatalogId, name: name },
      profile.jwt
    );
    navigate("/subscribe", { replace: true });
  };
  return (
    <div style={{ padding: 10 }}>
      <div>
        <Form.Label>ເພີ່ມຊື່</Form.Label>
        <Form.Control
          placeholder="name"
          size="lg"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div style={{ height: "10px" }} />
      <div>
        <Form.Label>ເລື່ອກແຄັດຕະລ໊ອກ</Form.Label>
        <Form.Select
          aria-label="ເລືອກ Catalog"
          value={selectCatalogId}
          onChange={(e) => {
            setSelectCatalogId(e.target.value);
          }}
          size="lg"
        >
          <option value="">ເລືອກ Catalog</option>
          {catalogs?.map((e) => (
            <option value={e?._id}>{e?.name}</option>
          ))}
        </Form.Select>
      </div>
      <div style={{ height: "20px" }} />
      <div>
        <Button
          style={{ width: "100%" }}
          disabled={!selectCatalogId}
          onClick={postSubscribe}
          size="lg"
        >
          ຍືນຍັນສ້າງໂຕດູດ
        </Button>
      </div>
    </div>
  );
}
