import React from "react";
import { Outlet } from "react-router-dom";

// page
import LoginPage from "../pages/auth/LoginPage";

// eslint-disable-next-line
export default {
  path: "/auth",
  element: <Outlet />,
  errorElement: <p>error</p>,
  children: [
    {
      path: "login",
      element: <LoginPage />,
    },
  ],
};
