import React from "react";
import { Button, Card } from "react-bootstrap";
import { MdAddCard } from "react-icons/md";
import { BsBank2 } from "react-icons/bs";

export default function WalletPage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "10px",
      }}
    >
      <Card border="dark" style={{ width: "100%" }}>
        <Card.Header>BCEL</Card.Header>
        <Card.Body>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "100px 1fr",
              gap: "10px",
            }}
          >
            <div>
              <BsBank2 style={{ width: "100%", height: "100%" }} />
            </div>
            <div>
              <Card.Title>ທະນາຄານການຄ້າຕ່າງປະເທດລາວ</Card.Title>
              <p>ຊື່ບັນຊີ: ABC-XXXXXX</p>
              <p>ເລກບັນຊີ: 2342-23223-23233</p>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button>ແກ້ໄຂ</Button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card border="dark" style={{ width: "100%" }}>
        <Card.Header>ເພີ່ມ</Card.Header>
        <Card.Body
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <MdAddCard style={{ width: "80px", height: "80px" }} />
        </Card.Body>
      </Card>
      <br />
    </div>
  );
}
