import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const ExampleChart = () => {
  const [chartData] = useState({
    series: [
      {
        name: "Servings",
        data: [44, 55, 41, 67, 22, 43, 21],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: ["#770049"],
      plotOptions: {
        bar: {
          columnWidth: "50%",
          color: "#770049",
          background: "#770049",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0,
      },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: ["31", "1", "2", "3", "4", "5", "6"],
        tickPlacement: "on",
      },

      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100],
        },
      },
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};
export default ExampleChart;
