import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import {
  subscribeDelete,
  subscribeGet,
} from "../../services/v1/subscribe.service";
import { useNavigate, useParams } from "react-router-dom";
import { useProvider } from "../../providers";
import { COLOR } from "../../constants";
import { FaRegStopCircle } from "react-icons/fa";
import { catalogItemsGet } from "../../services/v1/catalogItem.service";
import moment from "moment";

export default function SubscribeDetailPage() {
  const { subscribeId } = useParams();
  const navigate = useNavigate();
  // state
  const [detail, setDetail] = useState({});
  const [subscribe, setSubscribe] = useState();
  const [catalogItems, setCatalogItems] = useState([]);
  // provider
  const { profile } = useProvider();

  // useEffect
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    let allStock = 0;
    for (let item of catalogItems) {
      allStock += item.number;
    }
    setDetail({ allStock });
  }, [catalogItems]);

  // function
  const getData = async () => {
    const dataSubscribe = await getDataSubscribe();
    getDataCatalogItems(dataSubscribe?.catalogId?._id);
  };
  const getDataSubscribe = async () => {
    const data = await subscribeGet(subscribeId + "?p=catalogId", profile.jwt);
    setSubscribe(data);
    return data;
  };
  const getDataCatalogItems = async (catalogId) => {
    const data = await catalogItemsGet(`?catalogId=${catalogId}`, profile.jwt);
    setCatalogItems(data);
    return data;
  };
  const handleStopSubscribe = async () => {
    const data = await subscribeDelete(subscribeId, profile.jwt);
    if (!data.error) {
      navigate("/subscribe");
    }
  };
  return (
    <div style={{ padding: 10 }}>
      <div
        style={{
          backgroundColor: "#fff",
          padding: 10,
          boxShadow: "1px 1px 3px rgba(0,0,0,0.2)",
          fontSize: 12,
          "white-space": "pre-wrap",
          borderRadius: 8,
          display: "flex",
          flexDirection: "column",
          gap: 10,
          marginBottom: 10,
        }}
      >
        <div>
          <div>
            <span>ໂພສທີ່ດູດ: </span>
            <span style={{ color: COLOR.PRIMARY, fontWeight: "bold" }}>
              {subscribe?.postId}
            </span>
          </div>
          <div>
            <span>ຄວາມໄວການດູດ: </span>
            <span style={{ color: COLOR.PRIMARY, fontWeight: "bold" }}>
              ຄວາມໄວສູງ
            </span>
          </div>
          <div>
            <span>ແຄັດຕາລ໊ອກ: </span>
            <span style={{ color: COLOR.PRIMARY, fontWeight: "bold" }}>
              {subscribe?.catalogId?.name}
            </span>
          </div>
          <div>
            <span>ສິນຄ້າ: </span>
            <span style={{ color: COLOR.PRIMARY, fontWeight: "bold" }}>
              {catalogItems?.length}
            </span>
          </div>
          <div>
            <span>ຈຳນວນຍັງເຫຼືອ: </span>
            <span style={{ color: COLOR.PRIMARY, fontWeight: "bold" }}>
              {detail?.allStock || "-"}
            </span>
          </div>
          <div>
            <span>ຄອມເມັນ: </span>
            <span style={{ color: COLOR.PRIMARY, fontWeight: "bold" }}>-</span>
          </div>
          <div>
            <span>ດູດສຳເລັດ: </span>
            <span style={{ color: COLOR.PRIMARY, fontWeight: "bold" }}>-</span>
          </div>
          <div>
            <span>ວັນທີເລີ່ມ: </span>
            <span style={{ color: COLOR.PRIMARY, fontWeight: "bold" }}>
              {moment(subscribe?.createdAt).format("DD/MM/YYYY HH:mm")}
            </span>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleStopSubscribe}
            size="sm"
            variant="outline-primary"
          >
            <FaRegStopCircle /> ຢຸດ
          </Button>
        </div>
      </div>
      <div style={{ wiidth: "100%", marginBottom: 10 }}>
        <ButtonGroup style={{ width: "100%" }}>
          <Button variant="light" size="sm" style={{ width: "100%" }} active>
            ແຄັດຕາລ໊ອກ
          </Button>
          <Button variant="light" size="sm" style={{ width: "100%" }}>
            ລູກຄ້າ
          </Button>
        </ButtonGroup>
      </div>
      <div style={{ marginBottom: 64 }}>
        {catalogItems?.map((e) => (
          <div
            style={{
              display: "flex",
              gap: 10,
              backgroundColor: "#fff",
              padding: 5,
              borderRadius: 4,
              boxShadow: "1px 1px 3px rgba(0,0,0,0.2)",
              marginBottom: 5,
            }}
          >
            <div
              style={{
                width: 60,
                height: 60,
                borderRadius: 5,
                backgroundColor: "#ccc",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
              }}
            >
              IMG
            </div>
            <div style={{ fontSize: 12 }}>
              <div style={{ fontWeight: "bold" }}>{e?.name}</div>
              <div>code: {e?.code}</div>
              <div>ຈຳນວນ: {e?.number}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
