import React from "react";
import Box from "../components/Box";
import { Outlet, useNavigate } from "react-router-dom";
import { useProvider } from "../providers";
import _touch from "../sounds/Iphone_touch_sound.wav";
import { IoChevronBackOutline } from "react-icons/io5";

export default function DetailLayout() {
  // const navigate = useNavigate();
  // provider

  return (
    <Box
      style={{
        "background-image":
          "-webkit-linear-gradient(62deg, #dddddd 0%, #f3f8ff 100%)",
        "background-image":
          "-moz-linear-gradient(62deg, #dddddd 0%, #f3f8ff 100%)",
        "background-image":
          "-o-linear-gradient(62deg, #dddddd 0%, #f3f8ff 100%)",
        "background-image": "linear-gradient(62deg, #dddddd 0%, #f3f8ff 100%)",
        height: "100dvh",
        overflow: "auto",
      }}
    >
      <NavbarComponent />
      <div
        style={{
          height: "calc( 100dvh - 54px - env(safe-area-inset-bottom) )",
          position: "relative",
        }}
      >
        <Outlet />
      </div>
    </Box>
  );
}

function NavbarComponent() {
  const navigate = useNavigate();
  // state ----------------------------------------------------------------------------
  // const [data, setData] = useState();
  // provider
  const { profileFacebook, setProfileFacebook, profile, logout } =
    useProvider();
  // function
  const responseFacebook = (response) => {
    console.log(response);
    setProfileFacebook(response);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0 10px",
        gap: 10,
        height: "54px",
        color: "#909090",
        borderBottom: "0.2px solid #dedede",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div
        onClick={() => navigate(-1)}
        style={{
          fontSize: 26,
          fontWeight: "700",
          color: "#770049",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
        }}
      >
        <IoChevronBackOutline
          style={{ width: 26, height: 26, color: "#770049" }}
        />
      </div>
      <div style={{ flex: 1 }}></div>
    </div>
  );
}
