import React from "react";
// import { Outlet } from "react-router-dom";

// page
import StockPage from "../pages/stock";
import FbpagePage from "../pages/fbpage";

// eslint-disable-next-line
export default {
  path: "/fbpage",
  element: <FbpagePage />,
  errorElement: <p>error</p>,
  // children: [
  //   {
  //     path: "settingTable/:id",
  //     element: <SettingTable />,
  //   },
  // ],
};
