import React, { useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useProvider } from "../../providers";
import { useNavigate } from "react-router-dom";
import { FaBoxesPacking } from "react-icons/fa6";
import Box from "../../components/Box";
import { COLOR } from "../../constants";
import { Button, Card } from "react-bootstrap";

export default function HomePage() {
  const navigate = useNavigate();
  // provider
  const {
    profileFacebook,
    setProfileFacebook,
    myPages,
    setMyPages,
    selectPages,
    setSelectPages,
  } = useProvider();

  return (
    <div style={{ padding: 10 }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr 1fr", md: "1fr 1fr 1fr 1fr" },
          gridGap: 10,
          gap: 10,
        }}
      >
        {[
          {
            icon: "sd",
            title: "ສະຕ໊ອກ",
            value: "2309",
          },
          {
            icon: "sd",
            title: "ຍອດເງິນໃນລະບົບ",
            value: "60,000 ກີບ",
          },
          {
            icon: "sd",
            title: "ຍອດດູດ",
            value: "2309",
          },
          {
            icon: "sd",
            title: "ແພັກເກດທີ່ນຳໃຊ້",
            value: "Unlimited",
          },
        ].map((e, i) => (
          <Card key={i}>
            <Card.Header>
              <FaBoxesPacking />
              {e?.title}
            </Card.Header>
            <Card.Body>
              <h1>{e?.value}</h1>
            </Card.Body>
            <Card.Footer>
              <Button style={{ width: "100%" }}>ຊື່ເພີ່ມ</Button>
            </Card.Footer>
          </Card>
        ))}
      </Box>
      <div style={{ height: 10 }} />
      <Card body>This is some text within a card body.</Card>
    </div>
  );
}
