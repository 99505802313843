import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useProvider } from "../../providers";
import { useNavigate, useParams } from "react-router-dom";
import { fbpagepostsGet } from "../../services/v1/fbpagepost.service";
import { subscribesGet } from "../../services/v1/subscribe.service";
import { AiFillLike } from "react-icons/ai";
import { BiCommentDots } from "react-icons/bi";
import { Button } from "react-bootstrap";
import moment from "moment";
import "moment/locale/lo";

export default function SubscribePage() {
  const { fbId } = useParams();
  const navigate = useNavigate();
  // useState
  const [subscribesData, setSubscribesData] = useState([]);
  // provider
  const {
    profileFacebook,
    setProfileFacebook,
    myPages,
    setMyPages,
    selectPages,
    setSelectPage,
    profile,
    LoadingCallback,
  } = useProvider();

  // function
  const getData = async () => {
    const data = await LoadingCallback(subscribesGet("", profile?.jwt));
    if (!data.error) {
      setSubscribesData(data);
    }
  };
  // useEffect
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          padding: 10,
          gap: 10,
          gridGap: 10,
        }}
      >
        <div
          style={{
            backgroundColor: "#770049",
            height: 80,
            borderRadius: 8,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          <div>
            <span style={{ fontSize: 32 }}>{subscribesData?.length}</span>
            <span>/10</span>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#770049",
            height: 80,
            borderRadius: 8,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            fontWeight: "bold",
            fontSize: 12,
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-end",
              padding: 10,
            }}
          >
            <span style={{ fontSize: 24 }}></span>
            <span>ນຳໃຊ້ແບບບໍ່ຈຳກັດອໍເດີ</span>
          </div>
        </div>
      </div>
      <div style={{ padding: 10 }}>
        {subscribesData?.map((e) => (
          <div
            style={{
              padding: 10,
              backgroundColor: "#fff",
              borderRadius: 8,
              marginBottom: 5,
              boxShadow: "1px 1px 3px rgba(0,0,0,0.2)",
            }}
            onClick={() => navigate(`/subscribe/detail/${e?._id}`)}
          >
            <div style={{ fontSize: 12, "white-space": "pre-wrap" }}>
              {e?.name}
            </div>
            <div style={{ fontSize: 12, "white-space": "pre-wrap" }}>
              ເວລາ: {moment(e?.createdAt).fromNow()}
            </div>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 5,
                  fontSize: 12,
                  alignItems: "center",
                }}
              >
                <BiCommentDots />
                comment (10)
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 5,
                  fontSize: 12,
                  alignItems: "center",
                }}
              >
                <BiCommentDots />
                ດູດທັງໝົດ (10)
              </div>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
}

const Table = styled("table")({
  borderCollapse: "collapse",
  ["td,th"]: {
    border: "1px solid #000",
  },
  td: {
    maxWidth: 700,
  },
});
