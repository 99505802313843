import React from "react";
import Routes from "./routes";
import { ThemeProvider } from "styled-components";
import { Provider } from "./providers";
import { ToastContainer /* toast */ } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingComponent from "./components/LoadingComponent";

const theme = {
  xl: "@media screen and (min-width: 1536px)",
  lg: "@media screen and (min-width: 1200px)",
  md: "@media screen and (min-width: 900px)",
  sm: "@media screen and (min-width: 600px)",
  xs: "@media screen and (min-width: 0px)",
};
function App() {
  return (
    <Provider>
      <ThemeProvider theme={theme}>
        <div
          style={{
            width: "100%",
            height: "env(safe-area-inset-top)",
            backgroundColor: "#770049",
            position: "fixed",
            top: 0,
          }}
        ></div>
        <div
          style={{
            width: "100%",
            height: "env(safe-area-inset-bottom)",
            backgroundColor: "#770049",
            position: "fixed",
            bottom: 0,
            zIndex: 9999,
          }}
        ></div>
        <Routes />
        <ToastContainer />
        <LoadingComponent />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
