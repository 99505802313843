import React from "react";
// import { Outlet } from "react-router-dom";

// page
import HomePage from "../pages/home";

// eslint-disable-next-line
export default {
  path: "/home",
  element: <HomePage />,
  errorElement: <p>error</p>,
  // children: [
  //   {
  //     path: "settingTable/:id",
  //     element: <SettingTable />,
  //   },
  // ],
};
