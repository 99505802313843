import React from "react";
// import { Outlet } from "react-router-dom";

// page
import StockPage from "../pages/stock";
import FbpagePage from "../pages/fbpage";
import FbpagepostPage from "../pages/fbpagepost";
import SubscribePage from "../pages/subscribe";
import { Outlet } from "react-router-dom";
import SubscribeDetailPage from "../pages/subscribe/SubscribeDetailPage";
import CreateSubscribePage from "../pages/subscribe/CreateSubscribePage";

// eslint-disable-next-line
export default {
  path: "/subscribe",
  element: <Outlet />,
  errorElement: <p>error</p>,
  children: [
    {
      path: "",
      element: <SubscribePage />,
    },
    {
      path: "detail/:subscribeId",
      element: <SubscribeDetailPage />,
    },
    { path: "create", element: <CreateSubscribePage /> },
  ],
};
