import React from "react";
import { Outlet } from "react-router-dom";

// page
import StockPage from "../pages/stock";
import ItemStockPage from "../pages/stock/ItemStockPage";
import MainLayout from "../layouts/MainLayout";
import DetailLayout from "../layouts/DetailLayout";
import LivePage from "../pages/live";
import MenuPage from "../pages/menu";

// eslint-disable-next-line
export default {
  path: "/menu",
  element: <Outlet />,
  errorElement: <p>error</p>,
  children: [
    {
      path: "",
      element: <MenuPage />,
    },
  ],
};
