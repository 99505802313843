import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { productsGet } from "../../services/v1/product.service";
import { useProvider } from "../../providers";
import { Form } from "react-bootstrap";
export default function ItemStockPage() {
  const navigate = useNavigate();
  // state
  const [products, setProducts] = useState([]);
  // provider
  const { profile, LoadingCallback } = useProvider();
  // useEffect
  useEffect(() => {
    getData();
  }, []);
  // function
  const getData = async () => {
    const data = await LoadingCallback(productsGet("", profile.jwt));
    if (!data.error) {
      setProducts(data);
    }
  };
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",

        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ padding: "10px" }}>
        <Form.Control placeholder="Search" />
      </div>
      <div
        style={{
          height: "100%",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          flex: 1,
          padding: "0px 10px",
        }}
      >
        {products?.map((e, i) => (
          <div
            style={{
              display: "flex",
              gap: 10,
              backgroundColor: "#fff",
              padding: 5,
              borderBottom: "0.5px solid rgba(0,0,0,0.2)",
            }}
            key={i}
          >
            <div
              style={{
                width: 60,
                height: 60,
                borderRadius: 5,
                backgroundColor: "#ccc",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
              }}
            >
              IMG
            </div>
            <div style={{ fontSize: 12 }}>
              <div style={{ fontWeight: "bold" }}>{e?.name}</div>
              <div>code: {e?.code}</div>
              <div>price: {e?.price}</div>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          width: 60,
          height: 60,
          backgroundColor: "#770049",
          borderRadius: 16,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 40,
          color: "#fff",
          boxShadow: "2px 2px 5px rgba(0,0,0,0.2)",
          position: "fixed",
          bottom: "calc( 20px + env(safe-area-inset-bottom) )",
          right: 20,
          opacity: 0.8,
        }}
        onClick={() => navigate("/stock/item/create")}
      >
        <IoMdAdd />
      </div>
    </div>
  );
}
