import axios from "axios";
import { API } from "../../constants";
import { toast } from "react-toastify";
export const fbpagesGet = async (findBy, token) => {
  try {
    const url = `${API?.API_V1}/fbpages${findBy}`;
    const res = await axios.get(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error(await error.message);
    return { error: true };
  }
};
export const fbpageGet = async (id, token) => {
  try {
    const url = `${API?.API_V1}/fbpage/${id}`;
    const res = await axios.get(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error(await error.message);
    return { error: true };
  }
};
