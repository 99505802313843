import React from "react";
import { Outlet } from "react-router-dom";

// page
import StockPage from "../pages/stock";
import ItemStockPage from "../pages/stock/ItemStockPage";
import MainLayout from "../layouts/MainLayout";
import DetailLayout from "../layouts/DetailLayout";
import CreateItemStockPage from "../pages/stock/CreateItemStockPage";
import CatalogStockPage from "../pages/stock/CatalogStockPage";
import CreateCatalogStockPage from "../pages/stock/CreateCatalogStockPage";
import CatalogItemStockPage from "../pages/stock/CatalogItemStockPage";
import CreateCatalogItemStockPage from "../pages/stock/CreateCatalogItemStockPage";

// eslint-disable-next-line
export default {
  path: "/stock",
  element: <Outlet />,
  errorElement: <p>error</p>,
  children: [
    {
      path: "",
      element: <MainLayout />,

      children: [
        {
          path: "",
          element: <StockPage />,
        },
      ],
    },
    {
      path: "item",
      element: <DetailLayout />,
      children: [
        {
          path: "",
          element: <ItemStockPage />,
        },
        {
          path: "create",
          element: <CreateItemStockPage />,
        },
      ],
    },
    {
      path: "catalog",
      element: <DetailLayout />,
      children: [
        {
          path: "",
          element: <CatalogStockPage />,
        },
        {
          path: "create",
          element: <CreateCatalogStockPage />,
        },
      ],
    },
    {
      path: "catalogitem/:catalogId",
      element: <DetailLayout />,
      children: [
        {
          path: "",
          element: <CatalogItemStockPage />,
        },
        {
          path: "create",
          element: <CreateCatalogItemStockPage />,
        },
      ],
    },
  ],
};
