import React, { useState } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";

export const AuthContext = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [profile, setProfile] = useLocalStorage("profile");
  const [profileFacebook, setProfileFacebook] = useState();
  const [myPages, setMyPages] = useState();
  const [selectPages, setSelectPages] = useState();

  const logout = () => {
    setProfile();
    setProfileFacebook();
    setIsLogin(false);
    window.history.replaceState(null, null, "/");
    navigate("/auth/login", { replace: true });
  };

  return {
    profileFacebook,
    setProfileFacebook,
    myPages,
    setMyPages,
    selectPages,
    setSelectPages,
    logout,
    isLogin,
    setIsLogin,
    profile,
    setProfile,
  };
};
