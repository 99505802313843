import React from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaPhotoVideo } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import { BiSolidStore, BiSolidMessageSquareEdit } from "react-icons/bi";
import { BsFillPeopleFill, BsImageFill } from "react-icons/bs";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MdSubscriptions } from "react-icons/md";
import { useProvider } from "../../providers";
import { BiSolidBusiness } from "react-icons/bi";

export default function MenuPage() {
  const navigate = useNavigate();

  const { profileFacebook, setProfileFacebook, profile, logout } =
    useProvider();
  return (
    <div>
      <div style={{ padding: 10 }}>
        <div
          style={{
            width: "100%",
            padding: "15px 10px",
            backgroundColor: "#fff",
            borderRadius: 8,
            boxShadow: "1px 1px 5px rgba(0,0,0,0.2)",
            display: "flex",
            alignItems: "center",
            gap: 10,
            fontSize: 18,
            fontWeight: "bold",
          }}
          onClick={() => {
            navigate("/fbpage", { replace: true });
          }}
        >
          <div
            style={{
              width: 40,
              height: 40,
              borderRadius: "50%",
            }}
          >
            <BiSolidBusiness style={{ width: "100%", height: "100%" }} />
          </div>
          <span>ເບິ່ງເພຈທັງໝົດ</span>
          <div style={{ flex: 1 }}></div>
        </div>
      </div>
      <div
        style={{
          padding: 10,
        }}
      >
        <h3>ຕັ້ງຄ່າເພີ່ມເຕີມ</h3>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          padding: 10,
          gap: 10,
          gridGap: 10,
        }}
      >
        {[
          {
            title: "ກະເປົ໋າເງິນ",
            icon: GiWallet,
            path: "/wallet",
            disabled: false,
          },
          {
            title: "ຕັ້ງຄ່າຂໍ້ຄວາມ",
            icon: BiSolidStore,
            path: "/message",
            disabled: false,
          },
          {
            title: "ຕາດຫຼາດ",
            icon: BiSolidStore,
            path: "/market",
            disabled: true,
          },
          {
            title: "ແນະນຳໝູ່",
            icon: BsFillPeopleFill,
            path: "/invest",
            disabled: true,
          },
          {
            title: "ຮູບພາບ",
            icon: BsImageFill,
            path: "/picture",
            disabled: true,
          },
          {
            title: "ແຕ່ງຮູບ",
            icon: BiSolidMessageSquareEdit,
            path: "/editor",
            disabled: true,
          },
          { title: "ໂພສທີ່ຕິດຕາມ", icon: MdSubscriptions, path: "/subscribe" },
        ].map((e, i) => (
          <div
            style={{
              backgroundColor: "#fff",
              boxShadow: "1px 1px 5px rgba(0,0,0,0.2)",
              borderRadius: 8,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 10,
              opacity: e?.disabled ? 0.5 : 1,
            }}
            onClick={() => {
              if (!e?.disabled) {
                navigate(e?.path);
              }
            }}
            key={i}
          >
            <e.icon style={{ width: 32, height: 32 }} />
            <div>{e?.title}</div>
          </div>
        ))}
      </div>
      <div style={{ borderTop: "1px solid #ccc", padding: 10 }}>
        <Button variant="light" style={{ width: "100%" }} onClick={logout}>
          ອອກຈາກລະບົບ
        </Button>
      </div>
    </div>
  );
}
