import axios from "axios";
import { API } from "../../constants";
import { toast } from "react-toastify";

export const authSignInPost = async ({ identifier, password }) => {
  try {
    const url = `${API?.API_V1}/auth/signin`;
    const res = await axios.post(url, { identifier, password });
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const authSignUpPost = async ({ name, role, username, password }) => {
  try {
    const url = `${API?.API_V1}/auth/signup`;
    const res = await axios.post(url, { name, role, username, password });
    return res.data;
  } catch (error) {
    return { error: true };
  }
};

export const authSignUpFacebookPost = async ({ accessToken, fbid }) => {
  try {
    const url = `${API?.API_V1}/auth/auth-signup-facebook`;
    const res = await toast.promise(
      axios.post(url, null, {
        headers: { facebookaccesstoken: accessToken, fbid: fbid },
      }),
      {
        pending: "ກຳລັງລົງຊື່ເຂົ້າໃຊ້...",
        success: {
          render() {
            return "ລົງຊື່ເຂົ້າໃຊ້ສຳເລັດ 👌";
          },
          autoClose: 1000,
        },
        error: {
          render() {
            return "Error ບໍ່ສາມາດລົງຊື່ເຂົ້າໃຊ້ໄດ້ 🤯";
          },
          autoClose: 1000,
        },
      }
    );
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
