export const PRIMARY = "#770049";
export const SECONDARY = "#e93721";
export const SUCCESS = "#0ca057";
export const DANGER = "#f3040a";
export const WARNING = "#e4c90c";
export const INFO = "#fc4039";
export const LIGHT = "#d4dbe0";
export const DARK = "#020001";
export const MUTED = "#6c757d";
export const WHITE = "#ffffff";
