import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { Button, Form, Table } from "react-bootstrap";
import ValidateComponent from "../../components/ValidateComponent";
import {
  catalogItemGet,
  catalogItemsGet,
} from "../../services/v1/catalogItem.service";
import { useProvider } from "../../providers";
import { catalogPost } from "../../services/v1/catalog.service";
import { useNavigate, useParams } from "react-router-dom";

export default function CreateCatalogStockPage() {
  const navigate = useNavigate();
  // state
  const [fromCatalog, setFromCatalog] = useState({
    name: "",
  });
  // provider
  const { profile, LoadingCallback } = useProvider();
  // function
  const handleSubmit = async () => {
    const data = await LoadingCallback(catalogPost(fromCatalog, profile.jwt));
    navigate(`/stock/catalogitem/${data?._id}`, { replace: true });
  };
  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      <div
        style={{
          height: "100%",
          overflow: "auto",
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        <Form.Group>
          <Form.Label>
            ຊື່ແຄັດຕາລ໊ອກ
            <ValidateComponent />
          </Form.Label>
          <Form.Control
            placeholder="ຊື່ແຄັດຕາລ໊ອກ"
            value={fromCatalog?.name}
            onChange={(e) => {
              setFromCatalog((prev) => {
                return { ...prev, name: e.target.value };
              });
            }}
          />
        </Form.Group>
      </div>
      <div>
        <Button
          style={{
            width: "100%",
            height: "54px",
            position: "fixed",
            bottom: "env(safe-area-inset-bottom)",
            borderRadius: 0,
          }}
          onClick={handleSubmit}
        >
          ບັນທຶກ
        </Button>
      </div>
    </div>
  );
}
