import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useProvider } from "../../providers";
import { useNavigate, useParams } from "react-router-dom";
import { fbpagepostsGet } from "../../services/v1/fbpagepost.service";
import {
  subscribePost,
  subscribesGet,
} from "../../services/v1/subscribe.service";
import { Button, Card, CardFooter } from "react-bootstrap";
import { AiFillLike } from "react-icons/ai";
import { BiCommentDots } from "react-icons/bi";
import { fbpageGet } from "../../services/v1/fbpage.service";

export default function FbpagepostPage() {
  const navigate = useNavigate();
  const { fbId } = useParams();
  // useState
  const [fbpagepostsData, setFbpagepostsData] = useState();
  const [fbpageData, setdbpageData] = useState();
  const [subscribeData, setSubscribeData] = useState();
  // provider
  const {
    profileFacebook,
    setProfileFacebook,
    myPages,
    setMyPages,
    selectPages,
    setSelectPage,
    profile,
    LoadingCallback,
  } = useProvider();

  // function
  const getData = async () => {
    const data = await LoadingCallback(fbpagepostsGet("", fbId, profile?.jwt));
    const pageData = await LoadingCallback(fbpageGet(fbId, profile?.jwt));
    const subscribe = await LoadingCallback(subscribesGet("", profile?.jwt));

    setdbpageData(pageData);
    setFbpagepostsData(data);
    setSubscribeData(subscribe);
  };
  const postSubscribe = async ({ postId, fbId, catalogId }) => {
    await subscribePost({ postId, fbId, catalogId }, profile.jwt);
  };

  useEffect(() => {
    if (!subscribeData) return;
    if (!fbpagepostsData) return;
    setFbpagepostsData((prev) => {
      if (!prev?.data || !Array.isArray(prev?.data)) {
        return [];
      }
      let data = prev?.data?.map((e) => {
        const check = subscribeData.find((sub) => e?.id == sub?.postId);
        if (check) {
          return { ...e, subscribe: true };
        }
        return e;
      });
      return { ...prev, data };
    });
  }, [subscribeData]);
  useEffect(() => {
    getData();
  }, []);
  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <div style={{ padding: 10 }}>
        {fbpagepostsData?.data?.map((e, i) => (
          <Card
            style={{
              padding: 10,
              backgroundColor: "#fff",
              borderRadius: 8,
              marginBottom: 5,
              boxShadow: "1px 1px 3px rgba(0,0,0,0.2)",
            }}
            key={i}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: 10,
              }}
            >
              <div
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={fbpageData?.picture}
                  alt=""
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
              <span style={{ fontWeight: "bold" }}>{fbpageData?.name}</span>
            </div>
            <div style={{ height: "10px" }} />
            <div style={{ fontSize: 12, "white-space": "pre-wrap" }}>
              {e?.message}
            </div>
            <div style={{ height: "10px" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                onClick={
                  () => {
                    navigate(`/subscribe/create?postId=${e?.id}&fbId=${fbId}`);
                  }
                  // postSubscribe({
                  //   postId: e?.id,
                  //   fbId: fbId,
                  //   catalogId: "6522df18a0295f91e5714f93",
                  // })
                }
                disabled={e?.subscribe}
                style={{ width: "100%", flex: 1 }}
              >
                ດູດ
              </Button>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}

const Table = styled("table")({
  borderCollapse: "collapse",
  ["td,th"]: {
    border: "1px solid #000",
  },
  td: {
    maxWidth: 700,
  },
});
