import React from "react";
import { Outlet } from "react-router-dom";

// page
import WalletPage from "../pages/wallet";

// eslint-disable-next-line
export default {
  path: "/wallet",
  element: <Outlet />,
  errorElement: <p>error</p>,
  children: [
    {
      path: "",
      element: <WalletPage />,
    },
  ],
};
