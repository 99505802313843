import axios from "axios";
import { API } from "../../constants";
import { toast } from "react-toastify";
export const productsGet = async (findBy, token) => {
  try {
    const url = `${API?.API_V1}/products${findBy}`;
    const res = await axios.get(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error(await error.message);
    return { error: true };
  }
};
export const productGet = async (id, token) => {
  try {
    const url = `${API?.API_V1}/product/${id}`;
    const res = await axios.get(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error(await error.message);
    return { error: true };
  }
};
export const productPost = async (body, token) => {
  try {
    const url = `${API?.API_V1}/product/create`;

    const res = await toast.promise(
      axios.post(
        url,
        { ...body },
        { headers: { Authorization: `Bearer ${token}` } }
      ),
      {
        pending: "ກຳລັງເພີ່ມທີ່ພັກ...",
        success: {
          render() {
            return "ເພີ່ມທີ່ພັກສຳເລັດ 👌";
          },
          autoClose: 1000,
        },
        error: {
          render() {
            return "Error ບໍ່ສາມາດເພີ່ມທີ່ພັກໄດ້ 🤯";
          },
          autoClose: 1000,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error(await error.message);
    return { error: true };
  }
};
export const productPut = async (id, body, token) => {
  try {
    const url = `${API?.API_V1}/product/update`;
    const res = await toast.promise(
      axios.put(
        url,
        { id: id, data: body },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      ),
      {
        pending: "ກຳລັງແກ້ໄຂທີ່ພັກ...",
        success: {
          render() {
            return "ແກ້ໄຂທີ່ພັກສຳເລັດ 👌";
          },
          autoClose: 1000,
        },
        error: "Error ບໍ່ສາມາດແກ້ໄຂທີ່ພັກໄດ້ 🤯",
      }
    );
    return res.data;
  } catch (error) {
    console.error(await error.message);
    return { error: true };
  }
};
export const productDelete = async (id, token) => {
  try {
    const url = `${API?.API_V1}/product/delete`;
    const res = await toast.promise(
      axios.delete(url, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        data: { id: id },
      }),
      {
        pending: "ກຳລັງລົບທີ່ພັກ...",
        success: {
          render() {
            return "ລົບທີ່ພັກສຳເລັດ 👌";
          },
          autoClose: 1000,
        },
        error: "Error ບໍ່ສາມາດລົບທີ່ພັກໄດ້ 🤯",
      }
    );
    return res.data;
  } catch (error) {
    console.error(await error.message);
    return { error: true };
  }
};
export const productCountGet = async (findBy, token) => {
  try {
    const url = `${API?.API_V1}/product/count${findBy}`;
    const res = await axios.get(url, null, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error(await error.message);
    return { error: true };
  }
};
