import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { catalogsGet } from "../../services/v1/catalog.service";
import { useProvider } from "../../providers";
import { catalogItemsGet } from "../../services/v1/catalogItem.service";
import moment from "moment";
export default function CatalogStockPage() {
  const navigate = useNavigate();
  // state
  const [catalogs, setCatalogs] = useState([]);
  const [catalogItems, setCatalogItems] = useState([]);
  // provider
  const { profile, LoadingCallback } = useProvider();
  // useEffect
  useEffect(() => {
    getData();
  }, []);

  // function
  const getData = async () => {
    const data = await LoadingCallback(catalogsGet("", profile.jwt));
    if (data) {
      setCatalogs(data);
    }
  };

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      <div style={{ height: "100%", overflow: "auto", padding: 10 }}>
        {catalogs?.map((e) => (
          <div
            style={{
              padding: 10,
              backgroundColor: "#fff",
              borderRadius: 8,
              marginBottom: 5,
              boxShadow: "1px 1px 3px rgba(0,0,0,0.2)",
            }}
            onClick={() => {
              navigate(`/stock/catalogitem/${e?._id}`);
            }}
          >
            <div style={{ fontSize: 12, fontWeight: "bold" }}>{e?.name}</div>
            <div style={{ fontSize: 12, "white-space": "pre-wrap" }}>
              {/* {[...new Array(100)].map((e, i) => "AF" + i + ", ")} */}
            </div>
            <div style={{ fontSize: 12, "white-space": "pre-wrap" }}>
              ວັນທີສ້າງ: {moment(e?.createdAt).format("DD/MM/YYYY HH:mm")}
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          width: 60,
          height: 60,
          backgroundColor: "#770049",
          borderRadius: 16,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 40,
          color: "#fff",
          boxShadow: "2px 2px 5px rgba(0,0,0,0.2)",
          position: "absolute",
          bottom: "calc( 20px + env(safe-area-inset-bottom) )",
          right: 20,
          opacity: 0.8,
        }}
        onClick={() => navigate("/stock/catalog/create")}
      >
        <IoMdAdd />
      </div>
    </div>
  );
}
