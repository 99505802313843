import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { COLOR } from "../../constants";
import { IoMdAdd } from "react-icons/io";
import { TbPlaylistAdd } from "react-icons/tb";
import { AiFillDelete, AiFillSetting } from "react-icons/ai";
import { productsGet } from "../../services/v1/product.service";
import { useProvider } from "../../providers";
import {
  catalogItemPost,
  catalogItemsGet,
} from "../../services/v1/catalogItem.service";
import { useParams } from "react-router-dom";
import { MdAdd } from "react-icons/md";

export default function CreateCatalogItemStockPage() {
  const { catalogId } = useParams();
  // state
  const [products, setProducts] = useState([]);
  const [catalogItems, setCatalogItems] = useState([]);
  // provider
  const { profile, LoadingCallback } = useProvider();
  // useEffect
  useEffect(() => {
    getDataProducts();
    getDataCatalogItems();
  }, []);
  // function
  const getDataProducts = async () => {
    const data = await LoadingCallback(productsGet("", profile.jwt));
    setProducts(data);
  };
  const getDataCatalogItems = async () => {
    const data = await LoadingCallback(
      catalogItemsGet(`?catalogId=${catalogId}`, profile.jwt)
    );
    setCatalogItems(data);
  };
  const createCatatlogItem = async ({ productId, code, name }) => {
    const body = {
      name: name,
      code: code,
      productId: productId,
      catalogId: catalogId,
    };
    await catalogItemPost(body, profile.jwt);
    getDataCatalogItems();
  };
  return (
    <div>
      <div
        style={{
          width: "100%",
          padding: 10,
          fontWeight: "bold",
          backgroundColor: "#fff",
          color: COLOR.PRIMARY,
          height: 54,
          borderBottom: "0.2px solid rgb(222, 222, 222)",
          display: "flex",
          alignItems: "center",
        }}
      ></div>
      <div style={{}}>
        {products
          ?.filter((e) => {
            let status = true;
            for (let catalogItem of catalogItems) {
              console.log("catalogItem", catalogItem);
              if (catalogItem.productId == e._id) {
                status = false;
                break;
              }
            }
            return status;
          })
          ?.map((e) => (
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "0.2px solid rgb(222, 222, 222)",
                  margin: "0",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: 10,
                }}
                onClick={() =>
                  createCatatlogItem({
                    name: e?.name,
                    code: e?.code,
                    productId: e?._id,
                  })
                }
              >
                <Button variant="primary" size="sm">
                  <MdAdd />
                </Button>
                <div>{e?.name}</div>
                <div style={{ flex: 1 }}></div>
                <div>90</div>

                <Button variant="primary" size="sm">
                  <AiFillSetting style={{ width: 16, height: 16 }} /> ຕັ້ງຄ່າ
                </Button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
