import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { COLOR } from "../../constants";
import { IoMdAdd } from "react-icons/io";
import { TbPlaylistAdd } from "react-icons/tb";
import { AiFillDelete, AiFillSetting } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { catalogItemsGet } from "../../services/v1/catalogItem.service";
import { useProvider } from "../../providers";
import { catalogGet } from "../../services/v1/catalog.service";

export default function CatalogItemStockPage() {
  const navigate = useNavigate();
  const { catalogId } = useParams();
  // state
  const [catalog, setCatalog] = useState();
  const [catalogItems, setCatalogItems] = useState([]);
  const { profile, LoadingCallback } = useProvider();
  // useEffect
  useEffect(() => {
    getDataCatalog();
    getDataCatalogItems();
  }, []);
  // function
  const getDataCatalog = async () => {
    const data = await LoadingCallback(catalogGet(catalogId, profile.jwt));
    setCatalog(data);
  };
  const getDataCatalogItems = async () => {
    const data = await LoadingCallback(
      catalogItemsGet(`?catalogId=${catalogId}`, profile.jwt)
    );
    setCatalogItems(data);
  };
  return (
    <div>
      <div
        style={{
          width: "100%",
          padding: 10,
          fontWeight: "bold",
          backgroundColor: "#fff",
          color: COLOR.PRIMARY,
          height: 54,
          borderBottom: "0.2px solid rgb(222, 222, 222)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>{catalog?.name}</div>
        <div style={{ flex: 1 }} />
        <div style={{ display: "flex", gap: 10 }}>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => {
              navigate("create");
            }}
          >
            <TbPlaylistAdd style={{ width: 16, height: 16 }} /> ເພີ່ມ
          </Button>
          <Button variant="outline-danger" size="sm">
            <AiFillDelete style={{ width: 16, height: 16 }} /> ລົບອອກ
          </Button>
        </div>
      </div>
      <div style={{}}>
        {catalogItems?.map((e) => (
          <div style={{ display: "flex", width: "100%" }}>
            <Form.Check // prettier-ignore
              type="checkbox"
              id={`check_${e?._id}`}
              style={{
                backgroundColor: "#fff",
                borderBottom: "0.2px solid rgb(222, 222, 222)",
                margin: "0",
                width: "100%",
              }}
            >
              <Form.Check.Label
                style={{ display: "flex", padding: 10, gap: 10 }}
              >
                <Form.Check.Input type="checkbox" />
                <div>
                  {e?.name}({e?.code})
                </div>
                <div style={{ flex: 1 }}></div>
                <div>{e?.number}</div>
              </Form.Check.Label>
            </Form.Check>
            <div
              style={{
                backgroundColor: "#fff",
                borderBottom: "0.2px solid rgb(222, 222, 222)",
                margin: "0",
                width: "130px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button variant="primary" size="sm">
                <AiFillSetting style={{ width: 16, height: 16 }} /> ຕັ້ງຄ່າ
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
