import React, { useEffect } from "react";
import { useProvider } from "../providers";
import decodeJwt from "jwt-decode";

function PrivateRoute({ children }) {
  const { profile, setIsLogin, isLogin, logout } = useProvider();
  useEffect(() => {
    console.log("profile", profile);
    if (!profile?.jwt) {
      logout();
      return console.log("EMPTY TOKEN");
    }
    const decodedToken = decodeJwt(profile.jwt);
    const expirationTime = decodedToken.exp * 1000;
    const now = new Date().getTime();
    const delay = expirationTime - now;
    if (delay <= 0) {
      return logout();
    } else {
      setIsLogin(true);
    }
    function tokenExpired() {
      console.log("Token has expired!");
      logout();
    }
    setTimeout(tokenExpired, delay);
  }, [profile]);

  return children;
}

export default PrivateRoute;
