import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { Button, Form } from "react-bootstrap";
import ValidateComponent from "../../components/ValidateComponent";
import { productPost } from "../../services/v1/product.service";
import { useProvider } from "../../providers";
import { useNavigate } from "react-router-dom";

export default function CreateItemStockPage() {
  const navigate = useNavigate();
  // state
  const [fromProduct, setFromProduct] = useState({
    name: "",
    price: 0,
    code: "",
    description: "",
    barcode: "",
  });
  // provider
  const { profile, LoadingCallback } = useProvider();
  // function
  const handleSubmit = async () => {
    console.log(fromProduct);
    const data = await LoadingCallback(productPost(fromProduct, profile.jwt));
    navigate("/stock/item");
  };
  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      <div
        style={{
          height: "100%",
          overflow: "auto",
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        <div style={{ display: "flex", gap: 10 }}>
          <div
            style={{
              width: 80,
              height: 80,
              borderRadius: 5,
              backgroundColor: "#ccc",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
            }}
          >
            IMG
          </div>
          <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
            <Button size="sm">Upload</Button>
            <Button size="sm">ເລືອກຮູບຈາກຄັງຮູບ</Button>
          </div>
        </div>
        <Form.Group>
          <Form.Label>
            ຊື່ສິນຄ້າ
            <ValidateComponent />
          </Form.Label>
          <Form.Control
            placeholder="ຊື່ສິນຄ້າ"
            value={fromProduct?.name}
            onChange={(e) => {
              setFromProduct((prev) => {
                return { ...prev, name: e.target.value };
              });
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            ລາຄາ
            <ValidateComponent />
          </Form.Label>
          <Form.Control
            type="number"
            placeholder="min:0"
            value={fromProduct?.price}
            onChange={(e) => {
              setFromProduct((prev) => {
                return { ...prev, price: e.target.value };
              });
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            ລະຫັດສິນຄ້າ
            <ValidateComponent />
          </Form.Label>
          <Form.Control
            placeholder="EX:TY45"
            value={fromProduct?.code}
            onChange={(e) => {
              setFromProduct((prev) => {
                return { ...prev, code: e.target.value };
              });
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>ລາຍລະອຽດ</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            style={{ resize: "none" }}
            value={fromProduct?.description}
            onChange={(e) => {
              setFromProduct((prev) => {
                return { ...prev, description: e.target.value };
              });
            }}
          />
        </Form.Group>
      </div>{" "}
      <div>
        <Button
          style={{
            width: "100%",
            height: "54px",
            position: "fixed",
            bottom: "env(safe-area-inset-bottom)",
            borderRadius: 0,
          }}
          onClick={handleSubmit}
        >
          ບັນທຶກ
        </Button>
      </div>
    </div>
  );
}
