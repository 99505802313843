import React, { useEffect, useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import {
  authSignInPost,
  authSignUpFacebookPost,
} from "../../services/v1/auth.service";
import { useProvider } from "../../providers";
import { useNavigate, useParams } from "react-router-dom";
import { myHostelPost } from "../../services/v1/hostel.service";
import { IoLogoFacebook } from "react-icons/io";
import { FcGoogle } from "react-icons/fc";
import Box from "../../components/Box";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import useQuery from "../../hooks/useQuery";
// import logoImage from "../../logo.png";

export default function LoginPage() {
  const navigate = useNavigate();
  let { fbtoken } = useQuery();
  // state ----------------------------------------------------------------------------
  const [agree, setAgree] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");

  // provider ----------------------------------------------------------------------------
  const { setToken } = useProvider();
  const { isLogin, setIsLogin } = useProvider();
  const { profileFacebook, setProfileFacebook, profile, setProfile } =
    useProvider();

  // useEffect
  useEffect(() => {
    if (fbtoken) {
      alert("test");
      responseFacebook({ accessToken: fbtoken });
    }
  }, [fbtoken]);

  // function ----------------------------------------------------------------------------
  const handleLogin = async () => {
    try {
      const resualt = await authSignInPost({ identifier, password });
      if (resualt.jwt) {
        setIsLogin(true);
        setToken(resualt.jwt);
        const hostel = await myHostelPost(resualt.jwt);
        if (!hostel) throw new Error("HOSTEL_EMPTY");
        navigate("/hostel", { replace: true });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const responseFacebook = async (response) => {
    try {
      setProfileFacebook(response);
      console.log("response", response);
      const _profile = await authSignUpFacebookPost({
        accessToken: response.accessToken,
        fbid: response.id,
      });
      setIsLogin(true);
      setProfile(_profile);
      navigate("/home", { replace: true });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100dvh",
        display: { md: "grid", xs: "block" },
        gridTemplateColumns: { md: "1fr 1fr", xs: "1fr" },
      }}
    >
      <div style={{ backgroundColor: "#909090" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          padding: 20,
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: 420,
            borderRadius: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            className="mb-3"
          >
            <img
              src="/android-chrome-512x512.png"
              alt=""
              style={{ width: "120px", height: "120px" }}
            />
          </div>
          <div>
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Username"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                size="lg"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                size="lg"
              />
            </Form.Group>
            <div
              className="mb-3"
              style={{ display: "flex", gap: 10, alignItems: "center" }}
            >
              <Form.Check
                type="checkbox"
                checked={agree}
                onChange={(e) => setAgree(e.target.checked)}
              />
              <span htmlFor="policy">
                ຍອມຮັບ{" "}
                <a href="/policy.html" style={{ textDecoration: "underline" }}>
                  ເງື່ອນໄຂການໃຊ້ງານລະບົບ
                </a>
              </span>
            </div>
            <Button
              variant="primary"
              type="submit"
              className="mb-5"
              style={{ width: "100%" }}
              disabled={!agree || !password || !identifier}
              onClick={handleLogin}
              size="lg"
            >
              LOGIN
            </Button>
            <Button
              variant="outline-primary"
              type="submit"
              className="mb-2"
              style={{
                width: "100%",
                borderRadius: 50,
                display: "flex",
                alignItems: "center",
                fontWeight: "initial",
                paddingLeft: 25,
                fontSize: 12,
                gap: 10,
              }}
              // disabled={!agree || !password || !identifier}
              onClick={() => {
                alert("Login with google comming soon!");
              }}
              // disabled
            >
              <FcGoogle style={{ width: 24, height: 24 }} />
              <span>LOGIN WITH GOOGLE</span>
            </Button>
            <FacebookLogin
              appId="1912566809168294"
              // autoLoad={true}
              fields="name,email,picture"
              scope="read_insights,publish_video,pages_manage_cta,pages_manage_instant_articles,pages_show_list,read_page_mailboxes,ads_management,business_management,pages_messaging,leads_retrieval,whatsapp_business_management,page_events,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_posts,pages_manage_engagement,"
              // scope="public_profile,user_birthday,user_hometown,user_location,user_photos,user_videos,user_friends,user_posts,user_gender,user_link,user_age_range,read_insights,publish_video,private_computation_access,pages_manage_cta,pages_manage_instant_articles,pages_show_list,read_page_mailboxes,ads_management,business_management,pages_messaging,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,publish_to_groups,groups_access_member_info,leads_retrieval,whatsapp_business_management,instagram_manage_messages,attribution_read,page_events,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_posts,pages_manage_engagement,instagram_shopping_tag_products,"
              // scope="public_profile,email,pages_show_list"
              callback={responseFacebook}
              isMobile={false}
              disableMobileRedirect={true}
              render={(renderProps) => (
                <Button
                  variant="outline-primary"
                  type="submit"
                  style={{
                    width: "100%",
                    borderRadius: 50,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "initial",
                    paddingLeft: 25,
                    fontSize: 12,
                    gap: 10,
                  }}
                  // disabled={!agree || !password || !identifier}
                  onClick={() => renderProps.onClick()}
                >
                  <IoLogoFacebook style={{ width: 24, height: 24 }} />
                  <span>LOGIN WITH FACEBOOK</span>
                </Button>
              )}
            />
          </div>
        </div>
      </div>
    </Box>
  );
}
