import axios from "axios";
import { API } from "../../constants";
export const fbpagepostsGet = async (findBy, fbId, token) => {
  try {
    const url = `${API?.API_V1}/fbpageposts${findBy}`;
    const res = await axios.get(url, {
      headers: {
        fbId: fbId,
        authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error(await error.message);
    return { error: true };
  }
};
