import React, { useState } from "react";
import Box from "../components/Box";
import { Outlet, useNavigate } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import packetJson from "../../package.json";
import { useProvider } from "../providers";
import { HiHome } from "react-icons/hi";
import {
  BsFillPinMapFill,
  BsPersonVcardFill,
  BsFillSignpost2Fill,
} from "react-icons/bs";
import { RiLiveFill } from "react-icons/ri";
import { FaBoxesPacking } from "react-icons/fa6";
import { RxAvatar } from "react-icons/rx";
import { IoNotificationsSharp } from "react-icons/io5";
import { RiMessengerFill, RiBillFill } from "react-icons/ri";
import _touch from "../sounds/Iphone_touch_sound.wav";
import useSound from "use-sound";
import LoadingScreen from "../components/loading/LoadingScreen";

// icons

export default function MainLayout() {
  // const navigate = useNavigate();
  // provider
  const { isLoading } = useProvider();
  return (
    <Box
      style={{
        "background-image":
          "-webkit-linear-gradient(62deg, #dddddd 0%, #f3f8ff 100%)",
        "background-image":
          "-moz-linear-gradient(62deg, #dddddd 0%, #f3f8ff 100%)",
        "background-image":
          "-o-linear-gradient(62deg, #dddddd 0%, #f3f8ff 100%)",
        "background-image": "linear-gradient(62deg, #dddddd 0%, #f3f8ff 100%)",
        height: "100dvh",
        overflow: "auto",
      }}
    >
      {/* <div
        style={{
          height: "env(safe-area-inset-top)",
          width: "100%",
          backgroundColor: "#000",
        }}
      ></div> */}
      <NavbarComponent />
      <div
        style={{
          height: "calc( 100dvh - 108px)",
          marginTop: "54px",
          position: "relative",
        }}
      >
        <Outlet />
        <div style={{ height: "54px" }} />
      </div>
      <BottomBar />
    </Box>
  );
}

function NavbarComponent() {
  const navigate = useNavigate();
  // state ----------------------------------------------------------------------------
  // const [data, setData] = useState();
  // provider
  const { profileFacebook, setProfileFacebook, profile, logout } =
    useProvider();
  // function
  const responseFacebook = (response) => {
    console.log(response);
    setProfileFacebook(response);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0 10px",
        gap: 10,
        height: "54px",
        color: "#909090",
        borderBottom: "0.2px solid #dedede",
        backgroundColor: "#FFFFFF",
        position: "fixed",
        top: "env(safe-area-inset-top)",
        zIndex: 999,
        width: "100%",
      }}
    >
      <div
        onClick={() => navigate("/stock", { replace: true })}
        style={{
          fontSize: 26,
          fontWeight: "700",
          color: "#770049",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
        }}
      >
        <img
          src="/apple-touch-icon.png"
          alt="logo"
          style={{ width: 26, height: 26 }}
        />
        Q23 Live
      </div>

      <div style={{ flex: 1 }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          height: "100%",
          padding: 10,
        }}
      >
        <span style={{ fontSize: 12, fontWeight: 700 }}>
          version {packetJson.version}
        </span>
      </div>
      {/* <div>
        <div
          style={{
            backgroundColor: "#e7eae6",
            width: 35,
            height: 35,
            borderRadius: "50%",
            color: "#2c2c2c",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RiBillFill style={{ width: 25, height: 25 }} />
        </div>
      </div>
      <div>
        <div
          style={{
            backgroundColor: "#e7eae6",
            width: 35,
            height: 35,
            borderRadius: "50%",
            color: "#2c2c2c",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RiMessengerFill style={{ width: 25, height: 25 }} />
        </div>
      </div> */}
      {/* <div onClick={() => navigate("/stock", { replace: true })}>stock</div>
      <div onClick={() => navigate("/fbpage", { replace: true })}>pages</div>
      <div onClick={() => navigate("/subscribe", { replace: true })}>subscribe</div>
      <div onClick={() => navigate("/status", { replace: true })}>status</div> */}
      {/* <div style={{ display: !profile ? "none" : "block" }}>
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          {profile?.data?.name}
        </div>
      </div>
      <div style={{ display: !profile ? "none" : "block" }}>
        <div
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "blue",
          }}
          onClick={() => logout()}
        >
          logout
        </div>
      </div> */}
    </div>
  );
}

const BottomBar = () => {
  const navigate = useNavigate();
  const barStyle = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    position: "fixed",
    bottom: "env(safe-area-inset-bottom)",
    left: 0,
    right: 0,
    height: "54px",
    color: "#909090",
    boxShadow: "-3px 0 5px rgba(0,0,0,0.1)",
  };
  const item = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    fontSize: 10,
    width: "100%",
  };

  const [runSound] = useSound(_touch, { volume: 0.1 });

  const messageSound = () => {
    navigator.vibrate(20);
    runSound();
  };
  return (
    <div style={barStyle}>
      <div
        style={item}
        onMouseDown={() => {
          navigate("/home", { replace: true });
          messageSound();
        }}
      >
        <HiHome style={{ width: 20, height: 20 }} />
        <div>ໜ້າຫຼັກ</div>
      </div>
      <div
        style={item}
        onMouseDown={() => {
          navigate("/stock", { replace: true });
          messageSound();
        }}
      >
        <FaBoxesPacking style={{ width: 20, height: 20 }} />
        <div>ສະຕ໊ອກ</div>
      </div>
      <div
        style={item}
        onMouseDown={() => {
          navigate("/live", { replace: true });
          messageSound();
        }}
      >
        <RiLiveFill style={{ width: 20, height: 20, color: "#770049" }} />
        <div>ຖ່າຍທອດສົດ</div>
      </div>
      <div
        style={item}
        onMouseDown={() => {
          navigate("/subscribe", { replace: true });
          messageSound();
        }}
      >
        <BsFillSignpost2Fill style={{ width: 20, height: 20 }} />
        <div>ໂພສທີດູດ</div>
      </div>
      {/* <div
        style={item}
        onMouseDown={() => {
          navigate("/notification", { replace: true });
          messageSound();
        }}
      >
        <IoNotificationsSharp style={{ width: 20, height: 20 }} />
        <div>ແຈ້ງເຕືອນ</div>
      </div> */}
      <div
        style={item}
        onMouseDown={() => {
          navigate("/menu", { replace: true });
          messageSound();
        }}
      >
        <RxAvatar style={{ width: 20, height: 20 }} />
        <div>ເມນູ</div>
      </div>
    </div>
  );
};
