import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useProvider } from "../../providers";
import { fbpagesGet } from "../../services/v1/fbpage.service";
import { useNavigate } from "react-router-dom";
import { BsCardChecklist } from "react-icons/bs";

export default function FbpagePage() {
  const navigate = useNavigate();
  // useState
  const [fbpagesData, setFbpagesData] = useState();
  // provider
  const {
    profileFacebook,
    setProfileFacebook,
    myPages,
    setMyPages,
    selectPages,
    setSelectPage,
    profile,
    LoadingCallback,
  } = useProvider();

  // function
  const getData = async () => {
    const data = await LoadingCallback(fbpagesGet("", profile?.jwt));
    setFbpagesData(data);
  };
  // useEffect
  useEffect(() => {
    getData();
  }, []);
  return (
    <div
      style={{
        padding: 10,
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      {fbpagesData?.map((e) => (
        <div
          style={{
            width: "100%",
            padding: 10,
            backgroundColor: "#fff",
            height: 50,
            borderRadius: 8,
            boxShadow: "1px 1px 5px rgba(0,0,0,0.2)",
            display: "flex",
            gap: 10,
          }}
          onClick={() => navigate(`/fbpagepost/${e?.fbId}`)}
        >
          <div
            style={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            <img
              src={e?.picture}
              alt=""
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
          <span>{e?.name}</span>
        </div>
      ))}
    </div>
  );
}

const Table = styled("table")({
  borderCollapse: "collapse",
  ["td,th"]: {
    border: "1px solid #000",
  },
  td: {
    maxWidth: 700,
  },
});
