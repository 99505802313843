import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

function TestPage() {
  const [text, setText] = useState(0);
  const handleClick = () => {
    window.parent.postMessage({ open: true }, "http://localhost:3000");
  };

  useEffect(() => {
    const handleMessage = (event) => {
      console.log(event.data);
      if (event.origin !== "http://localhost:3000") return;

      setText((prev) => prev + 1);
      // Send result back to iframe
      console.log(event);
      console.log(event.data);
      // event.source.postMessage(
      //   "Rendered component with message: " + event.data,
      //   event.origin
      // );
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  return (
    <div>
      <div>
        <h2>number: {text}</h2>
      </div>
      <Button onClick={handleClick}>click me</Button>
    </div>
  );
}

export default TestPage;
