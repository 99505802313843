import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useProvider } from "../../providers";
import { useNavigate } from "react-router-dom";
import { productsGet } from "../../services/v1/product.service";
import { BsCardChecklist } from "react-icons/bs";
import ExampleChart from "../../components/chart/ExampleChart";

export default function StockPage() {
  const navigate = useNavigate();
  // state
  const [products, setProducts] = useState();
  // provider
  const {
    profileFacebook,
    setProfileFacebook,
    myPages,
    setMyPages,
    selectPages,
    setSelectPages,
    profile,
  } = useProvider();

  // function
  const getData = async () => {
    console.log("first", profile?.jwt);
    const _data = await productsGet("", profile?.jwt);
    setProducts(_data);
  };
  // useEffect
  useEffect(() => {
    // getData();
  }, []);

  return (
    <div>
      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <div
          style={{
            width: "100%",
            padding: 10,
            backgroundColor: "#fff",
            height: 50,
            borderRadius: 8,
            boxShadow: "1px 1px 5px rgba(0,0,0,0.2)",
          }}
          onClick={() => navigate("/stock/item")}
        >
          <BsCardChecklist /> ສິນຄ້າ
        </div>
        {/* <div
          style={{
            width: "100%",
            padding: 10,
            backgroundColor: "#fff",
            height: 50,
            borderRadius: 8,
            boxShadow: "1px 1px 5px rgba(0,0,0,0.2)",
          }}
          onClick={() => navigate("/stock/category")}
        >
          <BsCardChecklist /> ໝວດສິນຄ້າ
        </div> */}
        <div
          style={{
            width: "100%",
            padding: 10,
            backgroundColor: "#fff",
            height: 50,
            borderRadius: 8,
            boxShadow: "1px 1px 5px rgba(0,0,0,0.2)",
          }}
          onClick={() => navigate("/stock/catalog")}
        >
          <BsCardChecklist /> ແຄັດຕາລ໊ອກ
        </div>
      </div>
      <div style={{ padding: "10px" }}>
        <ExampleChart />
      </div>
    </div>
  );
}

const Table = styled("table")({
  borderCollapse: "collapse",
  ["td,th"]: {
    border: "1px solid #000",
  },
});
