import { useState } from "react";

export const LoadingContext = () => {
  const [isLoading, setIsLoading] = useState(false);

  const LoadingCallback = async (promiseFunct) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      promiseFunct
        .then(resolve)
        .catch(reject)
        .finally(() => {
          setIsLoading(false);
        });
    });
  };
  return { isLoading, setIsLoading, LoadingCallback };
};
