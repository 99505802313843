import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { messageGet, messagePut } from "../../services/v1/message.service";
import { useProvider } from "../../providers";

export default function MessagePage() {
  const [message, setMessage] = useState("");
  // provider
  const { profile } = useProvider();

  // useEffect
  useEffect(() => {
    console.log("profile", profile);
    getData();
  }, []);

  //
  const getData = async () => {
    const data = await messageGet(profile?.data?.messageId, profile?.jwt);
    if (data?.error) return;
    setMessage(data?.text);
  };
  const handleUpdate = async () => {
    const data = await messagePut(
      profile?.data?.messageId,
      { text: message },
      profile?.jwt
    );
    if (data?.error) return;
  };
  return (
    <div style={{ padding: "10px" }}>
      <h6>ຕ້ອງມີຂໍ້ຄວາມ</h6>
      <div>
        <li>{"[[BILL]]"} ລະຫັດບິນຫຼືເລກອໍເດີ</li>
        <li>{"[[USER_NAME]]"} ຊື່ລູກຄ້າ</li>
        <li>{"[[PRODUCT_CODE]]"} ລະຫັດສິນຄ້າ</li>
        <li>{"[[PRODUCT_NAME]]"} ຊື່ສິນຄ້າ</li>
        <li>{"[[PRODUCT_PRICE]]"} ລາຄາສິນຄ້າ</li>
      </div>
      <textarea
        className="form-control"
        style={{
          minHeight: "250px",
        }}
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      ></textarea>
      <div style={{ height: "10px" }}></div>
      <Button style={{ width: "100%" }} onClick={handleUpdate}>
        Save
      </Button>
    </div>
  );
}
