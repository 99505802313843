import React from "react";
import { Context } from "./Context";
// context
// import { HostelContext } from "./context/hostelContext";
import { useSoundState } from "./context/soundState";
// import { RoomContext } from "./context/roomContext";
// import { BillContext } from "./context/billContext";
import { AuthContext } from "./context/authContext";
import { LoadingContext } from "./context/loadingContext";

export const Provider = ({ children }) => {
  const loadingContext = LoadingContext();
  // const hostelContext = HostelContext({ loadingContext });
  // const roomContext = RoomContext({ hostelContext });
  // const billContext = BillContext();
  const sound = useSoundState();
  const auth = AuthContext();
  let store = Object.assign(
    // billContext,
    // roomContext,
    // hostelContext,
    sound,
    auth,
    loadingContext
  );
  return <Context.Provider value={store}>{children}</Context.Provider>;
};
