import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

// layout
import MainLayout from "../layouts/MainLayout";

// page
import PAGE_404 from "../pages/404";

// routes
import PrivateRoute from "./PrivateRoute";

import HomeRoutes from "./Home.routes";
import PostPage from "../pages/fbpagepost";
import AuthRoutes from "./Auth.routes";
import StockRoutes from "./Stock.routes";
import FbpageRoutes from "./Fbpage.routes";
import FbpagepostRoutes from "./Fbpagepost.routes";
import SubscribeRoutes from "./Subscribe.routes";
import LiveRoutes from "./Live.routes";
import MenuRoutes from "./Menu.routes";
import WalletRoutes from "./Wallet.routes";
import MessageRoutes from "./Message.routes";
import TestPage from "../pages/test/TestPage";

function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <PrivateRoute>
          <MainLayout />
        </PrivateRoute>
      ),
      errorElement: <p>error</p>,
      children: [
        {
          path: "",
          element: <Navigate to="/home" replace={true} />,
        },
        HomeRoutes,
        FbpageRoutes,
        FbpagepostRoutes,
        SubscribeRoutes,
        LiveRoutes,
        MenuRoutes,
        WalletRoutes,
        MessageRoutes,
        {
          path: "/post",
          element: <PostPage />,
        },
      ],
    },
    {
      path: "/test",
      element: <TestPage />,
    },
    AuthRoutes,
    StockRoutes,
    {
      path: "*",
      element: <PAGE_404 />,
    },
  ]);
}

export default Router;
