import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useProvider } from "../../providers";
import { useNavigate } from "react-router-dom";
import { productsGet } from "../../services/v1/product.service";
import { BsCardChecklist } from "react-icons/bs";
import { Form } from "react-bootstrap";

export default function LivePage() {
  const navigate = useNavigate();
  // state
  const [products, setProducts] = useState();
  // provider
  const {
    profileFacebook,
    setProfileFacebook,
    myPages,
    setMyPages,
    selectPages,
    setSelectPages,
    profile,
  } = useProvider();

  // function
  const getData = async () => {
    console.log("first", profile?.jwt);
    const _data = await productsGet("", profile?.jwt);
    setProducts(_data);
  };
  // useEffect
  useEffect(() => {
    // getData();
  }, []);

  return (
    <div style={{ padding: 10, overflow: "auto", height: "100%" }}>
      <div
        style={{
          padding: 10,
          backgroundColor: "#fff",
          borderRadius: 8,
          boxShadow: "1px 1px 3px rgba(0,0,0,0.2)",
          marginBottom: 20,
        }}
      >
        {[...new Array(20)].map((e) => (
          <div
            style={{
              backgroundColor: "#fff",
              borderBottom: "0.5px solid rgba(0,0,0,0.2)",
            }}
          >
            <div>1. Product test</div>
            <div>SDFPT</div>
          </div>
        ))}
      </div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#fff",
          borderRadius: 8,
          boxShadow: "1px 1px 3px rgba(0,0,0,0.2)",
          height: 200,
        }}
      >
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          style={{ height: "100px" }}
        />
      </div>
    </div>
  );
}

const Table = styled("table")({
  borderCollapse: "collapse",
  ["td,th"]: {
    border: "1px solid #000",
  },
});
